import React, { useEffect, useState } from 'react'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'
import {
    FibaDatePicker, FibaDropdown,
    Form, ListScreen, send, useFibaMsgBox, useLoading
} from '../../components'
import { INFORMATIONSTATUS } from '../../constants/data.cont'
import InformationDetail from './InformationDetail'
import { useAuth } from '../../contexts/AuthContext'
import { Dialog } from "@progress/kendo-react-dialogs";

import { useLocation } from 'react-router-dom'
import { Field } from '@progress/kendo-react-form'
import FibaButton from '../../components/button/FibaButton/FibaButton'
import { dateFormat } from '../../components/service/functions'

const Information = () => {

    const { t } = useTranslation()
    const gridRef = React.useRef(null);
    const { error, success, confirm } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const { auth } = useAuth()
    const location = useLocation();
    let dataItem = location.state ? location.state.dataitem : null;
    const [dataDetail, setDataDetail] = useState({
        showDetail: dataItem ? true : false, data: dataItem ? dataItem : {}
    });
    const [modalState, setmodalState] = useState(false)

    const remove = async (data) => {
        const isconfirm = await confirm(t('confirmdelete'));
        if (isconfirm) {
            const res = await send("lo010109", data, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'));
                refreshData();
            }
        }
    }

    const CustomCell = ({ dataItem, field, ariaColumnIndex, className, colSpan, columnIndex }) => {
        return <td
            colSpan={colSpan}
            className={className}
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
        >
            <div className='line-clamp-3'>
                {dataItem[field]}
            </div>
        </td>
    }

    const CommandCell = ({ dataItem }) => {
        return <td>
            <button
                className="mr-1 inline-flex items-center rounded-full border border-transparent bg-primary-500 sm:p-1 p-2 text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => openDialogDetail(dataItem)}
                title={t('more')}
                type="button"
            >
                <i className="fiba-i-edit" />
            </button>

            {(auth.user.role < 2) && <button
                className="inline-flex items-center rounded-full border border-transparent bg-rose-600 sm:p-1 p-2 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                title={t('delete')}
                onClick={() => remove(dataItem)}
                type="button"
            >
                <i className="fiba-i-delete" />
            </button>}
        </td>
    }

    const refreshData = () => {
        if (gridRef.current) {
            gridRef.current.readData();
        }
    };

    const openDialogDetail = async (dataItem) => {
        setDataDetail({ showDetail: true, data: dataItem });
    }

    const openExportDialog = () => {
        setmodalState(true)
    }

    const excelExport = async (data) => {
        const res = await send('lo010116', data, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            const downloadLink = document.createElement("a");
            downloadLink.href = res;
            downloadLink.download = "Аюулын мэдээлэл.xlsx";
            downloadLink.click();
        }
    }

    useEffect(() => {
        setDataDetail({ showDetail: dataItem ? true : false, data: dataItem ? dataItem : {} })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state])

    return (
        <div>
            <ListScreen
                read={'lo010108'}
                delete={'lo010109'}
                createable={false}
                ref={gridRef}
                deleteable={false}
                downloadable={true}
                excelExportCustom={openExportDialog}
                detailFuncion={openDialogDetail}
                selectedFieldName={'id'}
            >
                <GridColumn field='id' title={t('id')} filter='numeric' />
                <GridColumn field='created_at' title={t('tcreated_at')} filter='text' />
                <GridColumn field='userdivision' title={t('userdivision')} filter='text' />
                <GridColumn field='username' title={t('tusername')} filter='text' />
                <GridColumn field='phone' title={t('phone')} filter='text' />
                <GridColumn field='address' title={t('address')} filter='text' cell={CustomCell} />
                <GridColumn field='division' title={t('threatdivision')} filter='text' />
                <GridColumn field='impactthread' title={t('impactthread')} filter='text' />
                <GridColumn field='hazardclassification' title={t('hazardclassification')} filter='text' />
                <GridColumn field='levelrisk' title={t('levelrisk')} filter='text' />
                <GridColumn field='definitionrisk' title={t('definitionrisk')} filter='text' cell={CustomCell} />
                <GridColumn field='promptaction' title={t('promptaction')} filter='text' cell={CustomCell} />
                <GridColumn field='shiidwerlesentailbar' title={t('shiidwerlesentailbar')} filter='text' cell={CustomCell} />
                <GridColumn field='enddate' title={t('enddate')} filter='date' />
                <GridColumn field='status' title={t('status')} filter='text' />
                <GridColumn
                    cell={CommandCell}
                    width={80}
                >
                </GridColumn>

            </ListScreen>
            {dataDetail.showDetail && (
                <InformationDetail
                    closeModal={() => {
                        setDataDetail({ showDetail: false });
                        refreshData();
                    }}
                    modalState={dataDetail.showDetail}
                    data={dataDetail.data}
                />
            )}
            {modalState && (
                <Dialog
                    title={t("search")}
                    onClose={() => { setmodalState(false) }}
                    width={"300px"}
                    height={"auto"}
                >
                    <Form
                        onSubmit={excelExport}
                        showAction={false}
                        initialValues={{
                            createddatestart: dateFormat(new Date()),
                            createddateend: dateFormat(new Date()),
                        }}
                    >
                        <div className='mt-2'>
                            <Field
                                name={'createddatestart'}
                                component={FibaDatePicker}
                                label={t('Эхлэх огноо')}
                                required={true}
                            />
                        </div>
                        <div className='mt-2'>
                            <Field
                                name={'createddateend'}
                                component={FibaDatePicker}
                                label={t('Дуусах огноо')}
                                required={true}
                            />
                        </div>
                        <div className='my-2'>
                            <Field
                                name={'status'}
                                component={FibaDropdown}
                                label={t('status')}
                                data={INFORMATIONSTATUS}
                            />
                        </div>
                        <div className='flex justify-center'>
                            <FibaButton className={'my-2 w-[200px] text-center mx-auto'} type='submit'>Хайх</FibaButton>
                        </div>
                    </Form>
                </Dialog>
            )}
        </div>
    )
}

export default Information
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { send, SERVER_HOST, useFibaMsgBox, useLoading } from '../../components';
import { Dialog } from "@progress/kendo-react-dialogs";
import { INFORMATIONSTATUS } from '../../constants/data.cont';
import FibaButton from '../../components/button/FibaButton/FibaButton';
import { useAuth } from '../../contexts/AuthContext';
import InformationShare from './InformationShare'
import InformationDecide from './InformationDecide'
import { CheckIcon } from '@heroicons/react/24/outline';
import { savePDF } from '@progress/kendo-react-pdf';
import InformationCorr from './InformationCorr';

const InformationDetail = ({ modalState, data, closeModal }) => {

    const { t } = useTranslation()
    const container = React.useRef(null);
    const { error, confirm, success } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const [datatmp, setData] = useState({})
    const { auth } = useAuth()
    const [informationShare, setInformationShare] = useState({
        showDetail: false, data: {}
    });
    const [successdata, setDataSuccess] = useState({
        showDetail: false, data: {}
    });
    const [correction, setcorrection] = useState({
        showDetail: false, data: {}
    });

    const exportPDFWithMethod = () => {
        let element = container.current || document.body;
        showLoading(true)
        savePDF(element, {
            paperSize: "A4",
            margin: 20,
            fileName: `${data.id} дугаартай аюулын мэдээлэл`,
        }, () => {
            showLoading(false)
        });
    };

    const getDetail = async () => {
        const res = await send("lo010110", { id: data.id }, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            if (res.statusid === 10) {
                res.status = t('returned')
            } else {
                for (let index = 0; index < INFORMATIONSTATUS.length; index++) {
                    const element = INFORMATIONSTATUS[index];
                    if ((element.value + '') === (res.statusid + '')) {
                        res.status = t(element.name);
                        break;
                    }
                }
            }
            
            setData(res);
        }
    }

    const sendCofirmRequest = async () => {
        const isconfirmtxn = await confirm(t('confirminformation'));
        if (isconfirmtxn) {
            const res = await send("lo010114", { id: data.id }, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'))
                closeModal()
            }
        }
    }

    const correctionRequest = async () => {
        setcorrection({ showDetail: true, data: datatmp });
    }

    const ShowDetail = ({ ispdf = false }) => {
        const details = [];

        for (const key in datatmp.data) {
            if (Object.prototype.hasOwnProperty.call(datatmp.data, key)) {
                const element = datatmp.data[key];
                details.push(
                    <React.Fragment key={key}>
                        <div className={`${ispdf ? '' : 'sm:'}text-right font-bold ${key === 'expirydate' ? 'text-red-500' : ''}`}>
                            {t(key)}:
                        </div>
                        <div>{element}</div>
                    </React.Fragment>
                );
            }
        }

        return <>{details}</>;
    };

    const openDialogSuccess = async () => {
        setDataSuccess({ showDetail: true, data: datatmp });
    }

    const openDialogShare = async () => {
        setInformationShare({ showDetail: true, data: datatmp });
    }

    useEffect(() => {
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("more")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"80vh"}
                >
                    <div className='flex justify-end mb-2'>
                        <FibaButton
                            onClick={exportPDFWithMethod} themeColor='primary'
                            icon={<i className="fiba-i-printer mr-2" />}
                        >
                            {t('pdf')}
                        </FibaButton>
                    </div>

                    {(datatmp.statusid === 3 && auth.user.role < 3) && <div className='flex justify-end mb-2 space-x-2'>
                        <FibaButton onClick={correctionRequest} themeColor='none'>
                            {t('correction')}
                        </FibaButton>
                        <FibaButton onClick={sendCofirmRequest} themeColor='secondary'>
                            {t('confirm')}
                        </FibaButton>
                    </div>}

                    {(datatmp.statusid === 1 && auth.user.role < 4) && <div className='flex justify-end mb-2'>
                        <FibaButton onClick={correctionRequest} themeColor='secondary'>
                            {t('correction')}
                        </FibaButton>
                    </div>}

                    <div className='flex justify-end mb-2 space-x-2'>
                        {(datatmp.statusid === 2 || datatmp.statusid === 5) && <FibaButton onClick={openDialogSuccess} themeColor='secondary'>
                            {t('decide')}
                        </FibaButton>}

                        {(auth.user.role < 3 && datatmp.statusid < 3) && <FibaButton onClick={openDialogShare} themeColor='secondary'>
                            {t('share')}
                        </FibaButton>}
                    </div>

                    {datatmp.statusid !== 10 && <div className='my-3' >
                        {/* INFORMATIONSTATUS */}
                        {datatmp.statusid !== 5 && <nav aria-label="Progress">
                            <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                                {INFORMATIONSTATUS.map((step, stepIdx) => {
                                    if ((step.value * 1) !== 5) {
                                        return <li key={t(step.name)} className="relative md:flex md:flex-1">
                                            {(step.value * 1) <= datatmp.statusid ? (
                                                <a href={step.href} className="group flex w-full items-center">
                                                    <span className="flex items-center px-4 py-2 text-sm font-medium">
                                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-secondary-600 group-hover:bg-secondary-800">
                                                            <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </span>
                                                        <span className="ml-4 text-sm font-medium text-gray-900">{t(step.name)}</span>
                                                    </span>
                                                </a>
                                            ) : (step.value * 1) === (datatmp.statusid + 1) ? (
                                                <a href={step.href} aria-current="step" className="flex items-center px-4 py-2 text-sm font-medium">
                                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-secondary-600">
                                                        <span className="text-secondary-600">{step.id}</span>
                                                    </span>
                                                    <span className="ml-4 text-sm font-medium text-secondary-600">{t(step.name)}</span>
                                                </a>
                                            ) : (
                                                <a href={step.href} className="group flex items-center">
                                                    <span className="flex items-center px-4 py-2 text-sm font-medium">
                                                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                            <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                                                        </span>
                                                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{t(step.name)}</span>
                                                    </span>
                                                </a>
                                            )}

                                            {stepIdx !== INFORMATIONSTATUS.length - 1 ? (
                                                <>
                                                    {/* Arrow separator for lg screens and up */}
                                                    <div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
                                                        <svg
                                                            fill="none"
                                                            viewBox="0 0 22 80"
                                                            preserveAspectRatio="none"
                                                            className="h-full w-full text-gray-300"
                                                        >
                                                            <path
                                                                d="M0 -2L20 40L0 82"
                                                                stroke="currentcolor"
                                                                vectorEffect="non-scaling-stroke"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </>
                                            ) : null}
                                        </li>
                                    } else {
                                        return "";
                                    }
                                })}
                            </ol>
                        </nav>}
                    </div>}
                    <div>
                        <div className='grid sm:grid-cols-2 gap-2'>
                            <div className='sm:text-right font-bold'>
                                {t('id')}:
                            </div>
                            <div>{datatmp.id}</div>
                            <div className='sm:text-right font-bold'>
                                {t('tcreated_at')}:
                            </div>
                            <div>{datatmp.created_at}</div>
                            <div className='sm:text-right font-bold'>
                                {t('tcreateduser')}:
                            </div>
                            <div>{datatmp.username}</div>
                            <div className='sm:text-right font-bold'>
                                {t('tcreateddivision')}:
                            </div>
                            <div>{datatmp.divisionname}</div>
                            {datatmp.shiidwerlesen && <>
                                <div className='sm:text-right font-bold'>
                                    {t('allocated')}:
                                </div>
                                <div>{datatmp.shiidwerlesen}</div>
                            </>}
                            <div className='sm:text-right font-bold text-green-500'>
                                {t('status')}:
                            </div>
                            <div>{datatmp.status}</div>
                            <ShowDetail />
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 p-4'>
                            <div className='font-bold'>
                                {`${t('problems')} ${t('picture')}`}
                                <hr className='my-2' />
                                <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.photo}&type=GPA`} alt="" />
                            </div>
                            {
                                datatmp.shiidwerlesenphoto && <div>
                                    <div className='font-bold'>
                                        {`${t('decided')} ${t('picture')}`}
                                    </div>
                                    <hr className='my-2' />
                                    <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.shiidwerlesenphoto}&type=GPA`} alt="" />
                                </div>
                            }

                        </div>
                    </div>


                </Dialog>
            )}

            {informationShare.showDetail && (
                <InformationShare
                    closeModal={() => {
                        setInformationShare({ showDetail: false });
                        getDetail();
                    }}
                    modalState={informationShare.showDetail}
                    initdata={informationShare.data}
                />
            )}

            {successdata.showDetail && (
                <InformationDecide
                    closeModal={() => {
                        setDataSuccess({ showDetail: false });
                        getDetail();
                    }}
                    modalState={successdata.showDetail}
                    initdata={successdata.data}
                />
            )}

            {correction.showDetail && (
                <InformationCorr
                    closeModal={() => {
                        setcorrection({ showDetail: false });
                        getDetail();
                    }}
                    modalState={correction.showDetail}
                    initdata={correction.data}
                />
            )}

            <div style={{
                position: "absolute",
                left: "-1000px",
                top: 0,
                zIndex: -10000000,
            }}>
                <div ref={container} className='text-xs'>
                    <div className='grid grid-cols-2 gap-y-1 gap-x-2'>
                        <div className='text-right font-bold'>
                            {t('id')}:
                        </div>
                        <div>{datatmp.id}</div>
                        <div className='text-right font-bold'>
                            {t('created_at')}:
                        </div>
                        <div>{datatmp.created_at}</div>
                        {datatmp.shiidwerlesen && <>
                            <div className='text-right font-bold'>
                                {t('allocated')}:
                            </div>
                            <div>{datatmp.shiidwerlesen}</div>
                        </>}
                        <div className='text-right font-bold text-green-500'>
                            {t('status')}:
                        </div>
                        <div>{datatmp.status}</div>
                        <ShowDetail ispdf={true} />
                    </div>
                    <div className='grid grid-cols-2 gap-4 p-4'>
                        <div className='font-bold'>
                            {`${t('problems')} ${t('picture')}`}
                            <hr className='my-2' />
                            <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.photo}&type=GPA`} alt="" />
                        </div>
                        {
                            datatmp.shiidwerlesenphoto && <div>
                                <div className='font-bold'>
                                    {`${t('decided')} ${t('picture')}`}
                                </div>
                                <hr className='my-2' />
                                <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.shiidwerlesenphoto}&type=GPA`} alt="" />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default InformationDetail